import React, {Fragment} from 'react';

import {graphql} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';

import Layout from '../components/layout';

import {
  description as description_style,
  members as members_style,
  memberImage,
  memberBio,
  memberName
} from './styles/board.module.scss';

const BoardPage = ({data}: any) => {
  const {markdownRemark} = data;
  const {
    frontmatter: {title, description, members}
  } = markdownRemark;

  return (
    <Layout title={title} metaDescription={description}>
      <p className={description_style}>{description}</p>
      <div className={members_style}>
        {members.map((member: {name: string; picture: any; bio: string}) => (
          <Fragment key={`board-${member.name}`}>
            <div>
              <GatsbyImage
                image={member.picture.childImageSharp.gatsbyImageData}
                className={memberImage}
                alt={member.name}
              />
              <h2 className={memberName}>{member.name}</h2>
            </div>
            <p className={memberBio}>{member.bio}</p>
          </Fragment>
        ))}
      </div>
    </Layout>
  );
};

// eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: {path: {eq: $path}}) {
      frontmatter {
        title
        description
        members {
          name
          title
          bio
          picture {
            childImageSharp {
              gatsbyImageData(
                width: 232
                height: 232
                transformOptions: {grayscale: true}
                layout: CONSTRAINED
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`;

export default BoardPage;
